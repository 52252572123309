html,
body,
body > div {
  /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.fc-event-container {
  display: flex;
  justify-content: left;
  align-items: center;
  color: #ffffff;
  font-size: 0.78rem;
  padding: 0.06rem 0.06rem 0.2rem 0.2rem;
  border-radius: 0.3rem;
  width: 100%;
  height: 100%;
}

.fc-event-icon {
  font-size: 0.7rem;
  margin-right: 0.25rem;
}
